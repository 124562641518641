.home {
    display: flex;
    height: 100vh;
    background-image: url("../images/background.jpg");
    background-position: 120% bottom;
    background-size: 66%;
    background-repeat: no-repeat;
    .container {
        margin: 9% auto auto;
        z-index: 1;
    }
    .background-shape, .background-shape-reversed {
        position: absolute;
        bottom: -12%;
        left: -8%;
        z-index: 0;
    }
    .background-shape-reversed {
        top: -9%;
        right: -78%;
        img {
            transform: rotate(185deg);
        }
    }
    .slogan {
        color: white;
        font-size: 1.5em;
        letter-spacing: 0.2em;
        margin-top: 40px;
        font-family: sans-serif;
        span {
            color: grey
        }
    }

    .searchbar {
        margin-top: 70px;
        input {
            &::placeholder {
                color: #adadad;
            }
            width: 660px;
            height: 70px;
            border-radius: 15px;
            color: white;
            background-color: #848484a3;
            border: none;
            box-shadow: inset 0 0 14px #0000007a;
            padding: 0 30px;
            box-sizing: border-box;
            font-size: 1.5em;
        }
        button {
            background: none;
            border: none;
            margin-left: -55px;
            opacity: 0.7;
            position: relative;
            top: 6px;
        }
    }
    
}